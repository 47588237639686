.dcp_edge-index-page {
    height: 100vh;
    background-color: var(--theme-background);
}

.dcp_edge-index-page .sign-btn-devider {
    width: 240px;
    height: 1px;
    background: #484D53;
    @apply mx-auto;
    margin-top: 16px;
}

.dcp_edge-index-page .page-main-heading {
    color: #000000;
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    width: 360px;
    font-family: "suisseintl";

    @media only screen and (min-width: 315px) and (max-width: 767px) {
        font-size: 34px;
    }
}

.dcp_edge-index-page .page-sub-heading-text {
    color: #484D53;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.3px;
    font-family: 'sf-ui-text';
    max-width: 350px;
    @apply x-sm:mx-auto;

    @media only screen and (min-width: 315px) and (max-width: 767px) {
        font-size: 16px;
        line-height: 19.5px;
    }
}

.dcp_edge-index-page .dcp_edge-img {
    width: 590px;
    height: 590px;

    @media only screen and (min-width: 315px) and (max-width: 767px) {
        width: 90%;
        height: auto;
        /* @apply x-sm:mx-auto; */
    }
}

.dcp_edge-index-page .index-footer {
    position: absolute;
    bottom: 40px;
    width: 100%;

    @media only screen and (min-width: 315px) and (max-width: 767px) {
        position: static;
        @apply px-4;
    }
}

.dcp_edge-index-page .footer-text {
    color: #484D53;
    font-family: "sf-ui-text";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    opacity: 0.6;
}

.app-container-dcp_edge .text-1-heading,
.dashboard-dcp_edge .text-1-heading {
    color: var(--theme-text_1_h_color);
    font-size: var(--theme-text_1_h_font_size);
    font-style: var(--theme-text_1_h_font_style);
    line-height: var(--theme-text_1_h_leading);
    font-family: var(--theme-text_1_h_font_family);
    font-weight: var(--theme-text_2_h_font_weight);
    letter-spacing: var(--theme-text_1_h_letter_spacing);
    margin-top: var(--theme-text_1_h_mt);
    padding-top: var(--theme-text_1_h_pt);
}

.dashboard-dcp_edge .paymentCard .text-1-heading {
    color: #484D53;
}

.app-container-dcp_edge .text-1-heading-2,
.dashboard-dcp_edge .text-1-heading-2 {
    color: var(--theme-text_1_h_2_color);
    font-size: var(--theme-text_1_h_2_font_size);
    font-style: var(--theme-text_1_h_2_font_style);
    line-height: var(--theme-text_1_h_2_leading);
    font-family: var(--theme-text_1_h_2_font_family);
    font-weight: var(--theme-text_2_h_2_font_weight);
    letter-spacing: var(--theme-text_1_h_2_letter_spacing);
    margin-top: var(--theme-text_1_h_2_mt);
    padding-top: var(--theme-text_1_h_2_pt);
}

.app-container-dcp_edge .text-1-heading-3,
.dashboard-dcp_edge .text-1-heading-3 {
    color: var(--theme-text_1_h_3_color);
    font-size: var(--theme-text_1_h_3_font_size);
    font-style: var(--theme-text_1_h_3_font_style);
    line-height: var(--theme-text_1_h_3_leading);
    font-family: var(--theme-text_1_h_3_font_family);
    font-weight: var(--theme-text_2_h_3_font_weight);
    letter-spacing: var(--theme-text_1_h_3_letter_spacing);
    margin-top: var(--theme-text_1_h_3_mt);
    padding-top: var(--theme-text_1_h_3_pt);
}

.app-container-dcp_edge .text-2-heading,
.dashboard-dcp_edge .text-2-heading {
    color: var(--theme-text_2_h_color);
    font-size: var(--theme-text_2_h_font_size);
    font-style: var(--theme-text_2_h_font_style);
    line-height: var(--theme-text_2_h_leading);
    font-family: var(--theme-text_2_h_font_family);
    font-weight: var(--theme-text_2_h_font_weight);
    letter-spacing: var(--theme-text_2_h_letter_spacing);
    margin-top: var(--theme-text_2_h_mt);
    padding-top: var(--theme-text_2_h_pt);
}

.app-container-dcp_edge .paymentCard .text-1-heading-3,
.dashboard-dcp_edge .paymentCard .text-1-heading-3 {
    margin-bottom: 1rem;
}

.app-container-dcp_edge .add-pay-method-btn,
.dashboard-dcp_edge .add-pay-method-btn {
    text-align: left;
    margin-left: 1.5rem
}

.app-container-dcp_edge .brand-btn-void,
.dashboard-dcp_edge .brand-btn-void {
    color: var(--theme-brand_btn_void_color);
    font-size: var(--theme-brand_btn_void_font_size);
    font-style: var(--theme-brand_btn_void_font_style);
    font-weight: var(--theme-brand_btn_void_font_weight);
    line-height: var(--theme-brand_btn_void_leading);
    font-family: var(--theme-brand_btn_void_font_family);
    letter-spacing: var(--theme-brand_btn_void_letter_spacing);
    text-align: var(--theme-brand_btn_void_text_center);
    border-color: var(--theme-brand_btn_void_color);
}

.app-container-dcp_edge .brand-btn-solid,
.dashboard-dcp_edge .brand-btn-solid {
    color: var(--theme-brand_btn_solid_color);
    font-size: var(--theme-brand_btn_solid_font_size);
    font-style: var(--theme-brand_btn_solid_font_style);
    font-weight: var(--theme-brand_btn_solid_font_weight);
    line-height: var(--theme-brand_btn_solid_leading);
    font-family: var(--theme-brand_btn_solid_font_family);
    letter-spacing: var(--theme-brand_btn_solid_letter_spacing);
    text-align: var(--theme-brand_btn_solid_text_center);
    border-color: var(--theme-brand_btn_solid_color);
    background-color: var(--theme-brand_btn_solid_bg_color);
}

.app-container-dcp_edge .brand-btn-solid.btn-disabled,
.dashboard-dcp_edge .brand-btn-solid.btn-disabled {
    color: var(--theme-brand_btn_solid_disabled_color);
    background-color: var(--theme-brand_btn_solid_disabled_bg_color);
    border-color: var(--theme-brand_btn_solid_disabled_color);
}

.dashboard-dcp_edge .page-title {
    margin-top: 2em;
}

.dashboard-dcp_edge .page-sub-title {
    margin-top: 1em;
}

.dashboard-dcp_edge .text-caption-6 {
    font-family: var(--text-c6-font_family);
    font-weight: var(--text-c6-font_weight);
    font-size: var(--text-c6-font_size);
}

.dashboard-dcp_edge.auto-pay-dcp_edge .payment-all-details{
    color: var(--text-color);
    font-family: var(--text-b3-font_family);
    font-size: 13px;
    font-weight: 300;
}

.dashboard-dcp_edge .text-heading-1 {
    font-family: var(--text-h1-font_family);
    font-weight: var(--text-h1-font_weight);
    font-size: var(--text-h1-font_size);
    line-height: var(--text-h1-line_height);
    letter-spacing: var(--text-h1-letter_spacing);
}

.dashboard-dcp_edge .text-heading-2 {
    font-family: var(--text-h2-font_family);
    font-weight: var(--text-h2-font_weight);
    font-size: var(--text-h2-font_size);
    line-height: var(--text-h2-line_height);
}

.dashboard-dcp_edge .text-c2-std {
    font-family: var(--text-c2-std-font_family);
    font-weight: var(--text-c2-std-font_weight);
    font-size: var(--text-c2-std-font_size);
    line-height: var(--text-c2-std-line_height);
    letter-spacing: var(--text-c2-std-letter_spacing);
}

.dashboard-dcp_edge .text-t1-color {
    color: var(--text-t1-color);
}

.dashboard-dcp_edge .text-heading-5 {
    font-family: var(--text-h5-font_family);
    font-weight: var(--text-h5-font_weight);
    font-size: var(--text-h5-font_size);
}

.dashboard-dcp_edge .text-number-1 {
    font-family: var(--text-n1-font_family);
    font-weight: var(--text-n1-font_weight);
    font-size: var(--text-n1-font_size);
}

.dashboard-dcp_edge .text-number-3 {
    font-family: var(--text-n3-font_family);
    font-weight: var(--text-n3-font_weight);
    font-size: var(--text-n3-font_size);
    letter-spacing: var(--text-n3-letter_spacing);
}

.dashboard-dcp_edge .text-number-6 {
    font-family: var(--text-n6-font_family);
    font-weight: var(--text-n6-font_weight);
    font-size: var(--text-n6-font_size);
    letter-spacing: var(--text-n6-letter_spacing);
}

.dashboard-dcp_edge .btn-1 {
    font-family: var(--text-bn1-font_family);
    font-weight: var(--text-bn1-font_weight);
    font-size: var(--text-bn1-font_size);
    letter-spacing: var(--text-bn1-letter_spacing);
    color: var(--text-t4-color);
    background-color: var(--theme-br2);
    width: 140px;
    height: 66px;
    padding: 0;
}

.dashboard-dcp_edge .btn-3 {
    font-family: var(--text-bn3-font_family);
    font-weight: var(--text-bn3-font_weight);
    font-size: var(--text-bn3-font_size);
}

.dashboard-dcp_edge .text-c1 {
    font-family: var(--text-c1-font_family);
    font-weight: var(--text-c1-font_weight);
    font-size: var(--text-c1-font_size);
    letter-spacing: var(--text-c1-letter_spacing);
}

.dashboard-dcp_edge .text-web {
    font-family: var(--secondary-font);
    font-weight: var(--theme-text_2_h_font_weight);
    font-size: var(--text-h1-sm);
    letter-spacing: var(--letter-spacing);
}

.dashboard-dcp_edge .text-heading-4 {
    font-family: var(--text-h4-font_family);
    font-weight: var(--text-h4-font_weight);
    font-size: var(--text-h4-font_size);
    letter-spacing: var(--text-h4-letter_spacing);
}

.dashboard-dcp_edge .text-b3 {
    font-family: var(--text-b3-font_family);
    font-weight: var(--text-b3-font_weight);
    font-size: var(--text-b3-font_size);
    letter-spacing: var(--text-b3-letter_spacing);
}

.dashboard-dcp_edge .text-b4 {
    font-family: var(--text-b4-font_family);
    font-weight: var(--text-b4-font_weight);
    font-size: var(--text-b4-font_size);
    letter-spacing: var(--text-b4-letter_spacing);
}

.dashboard-dcp_edge .text-b5 {
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    line-height: var(--text-b5-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dcp_edge-index-page .text-b7,
.dashboard-dcp_edge .text-b7 {
    color: var(--text-b7-color);
    font-family: var(--text-b7-font_family);
    font-weight: var(--text-b7-font_weight);
    font-size: var(--text-b7-font_size);
    font-style: var(--text-b7-font_style);
    line-height: var(--text-b7-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-dcp_edge .text-b7 {
    color: var(--theme-card-text);
    font-family: var(--text-b7-font_family);
    font-weight: var(--text-b7-font_weight);
    font-size: var(--text-b7-font_size);
    font-style: var(--text-b7-font_style);
    line-height: var(--text-b7-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-dcp_edge .text-b5 {
    color: var(--theme-card-text);
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    font-style: var(--text-b5-font_style);
    line-height: var(--text-b5-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-dcp_edge .text-b10 {
    font-family: var(--text-b10-font_family);
    font-weight: var(--text-b10-font_weight);
    font-size: var(--text-b10-font_size);
    letter-spacing: var(--text-b10-letter_spacing);
}

.dashboard-dcp_edge .text-b11 {
    font-family: var(--text-b11-font_family);
    font-weight: var(--text-b11-font_weight);
    font-size: var(--text-b11-font_size);
    letter-spacing: var(--text-b11-letter_spacing);
    line-height: var(--text-b11-line_height);
    color: var(--text-b11-color);
}

.dashboard-dcp_edge .dfc-dashboard-balance {
    line-height: normal;
}

.sidebar-dcp_edge .text-heading-2 {
    font-family: var(--text-h2-font_family);
    font-weight: var(--text-h2-font_weight);
    font-size: var(--text-h2-font_size);
    line-height: var(--text-h2-line_height);
}

.sidebar-dcp_edge .text-b5 {
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    line-height: var(--text-b5-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

@media only screen and (min-width: 315px) and (max-width: 768px) {

    .app-container-dcp_edge .text-1-heading,
    .dashboard-dcp_edge .text-1-heading {
        font-size: var(--xsm-theme-text_1_h_font_size);
    }

    .app-container-dcp_edge .text-1-heading-2,
    .dashboard-dcp_edge .text-1-heading-2 {
        font-size: var(--xsm-theme-text_1_h_2_font_size);
    }

    .app-container-dcp_edge .nv-logo {
        width: 100%;
        height: 100%;
        margin-left: 1em;
    }

    .app-container-dcp_edge.signin-flow-page {
        margin-top: 56px;
        padding-top: 4em;
    }

    .app-container-dcp_edge.onb-workflow-page {
        margin-top: 80px;
    }
}

.app-container-dcp_edge .txtCreditScore.sub-text {
    color: #2D2D2D;
}

.congrats-qr-block-dcp_edge {
    box-shadow: -2px -2px 0px 0px rgb(251 251 251);
}

.congrats-qr-block-dcp_edge .congrats-qr-block-inner {
    border-radius: 15.404px;
    background: var(--bg3, #FFF);
    box-shadow: 18px 20px 15px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-profile-dcp_edge .edit-address-container,
.contact-us-dcp_edge,
.dashboard-profile-dcp_edge {
    background-color: #F9F7F5;
}

.dashboard-profile-dcp_edge .text-web,
.dashboard-profile-dcp_edge .text-field {
    color: #535150;
}

/* .dashboard-profile-dcp_edge .text-c1 {
    color: #000000;
} */

.dashboard-profile-dcp_edge .payment-success-thanks-message .text-c1{
    color: white;
}

.dashboard-profile-dcp_edge .payment-method-bank-name{
    color: var(--text-color);
    font-family: var(--text-b3-font_family);
    font-size: 10px;
    font-weight: 300;
}

.dashboard-profile-dcp_edge .payment-method-account-type{
    color: var(--text-color);
    font-family: var(--text-b3-font_family);
    font-size: 13px;
    font-weight: 300;
}

.dashboard-profile-dcp_edge .payment-method-last-digits{
    color: #000000;
    font-family: var(--text-b3-font_family);
    font-size: 16px;
    font-weight: 300;
}

.dashboard-profile-dcp_edge .text-heading-4 {
    color: #535150;
}

.dashboard-dcp_edge .nodue-text {
    color: #fff;
}

.dashboard-dcp_edge .text-t1-headline.payment-success-btn,
.dashboard-dcp_edge .btn-1.payment-done-btn{
    background: rgba(0, 0, 0, 0.15);
    color: white;
  }

.dashboard-dcp_edge .new-design-guidelines {
  .bg-primary-tw {
    color: var(--theme-brand_btn_solid_color);
    font-size: var(--theme-brand_btn_solid_font_size);
    font-style: var(--theme-brand_btn_solid_font_style);
    line-height: var(--theme-brand_btn_solid_leading);
    font-family: var(--theme-brand_btn_solid_font_family);
    letter-spacing: var(--theme-brand_btn_solid_letter_spacing);
    text-align: var(--theme-brand_btn_solid_text_center);
    border-color: var(--theme-brand_btn_solid_color);
    background-color: var(--theme-brand_btn_solid_bg_color);
  }

  .gap-4{
    gap: 1rem;
  }

  .text-t1-headline {
    color: var(--text-t1-color);
  }

  .text-t2-regular {
    color: var(--text-t2-color);
  }

  .text-t2-body {
    color: var(--text-t2-color);
  }

  .text-bn1 {
    color: var(--text-t2-color);
  }

  .font-h2 {
    font-family: var(--text-h2-font_family);
    font-weight: var(--text-h2-font_weight);
    font-size: var(--text-h2-font_size);
    letter-spacing: var(--text-h2-letter_spacing);
    line-height: var(--text-h2-line_height);
    @media only screen and (min-width: 315px) and (max-width: 767px) {
        font-size: 22px;
        line-height: normal;
        font-weight: 700;
    }
  }

  .font-h4 {
    font-family: var(--text-h4-font_family);
    font-weight: var(--text-h4-font_weight);
    font-size: var(--text-h4-font_size);
    letter-spacing: var(--text-h4-letter_spacing);
  }

  .font-b4 {
    font-family: var(--text-b4-font_family);
    font-size: var(--text-b4-font_size);
    letter-spacing: var(--text-b4-letter_spacing);
  }

  .font-b4-bold {
    font-family: var(--text-b4-font_family);
    font-weight: var(--text-b4-font_weight);
    font-size: var(--text-b4-font_size);
    letter-spacing: var(--text-b4-letter_spacing);
  }

  .font-b6 {
    font-family: var(--text-n6-font_family);
    font-weight: var(--text-n6-font_weight);
    font-size: var(--text-n6-font_size);
    letter-spacing: var(--text-n6-letter_spacing);
  }
  .bg1 {
    background-color: var(--bg1);
  }

  .finicity-box {
    width: 576px;
    height: 580px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    position: relative;

    @media only screen and (min-width: 315px) and (max-width: 767px) {
        width: 100%;
        height: auto;
        padding-left: 27px;
        padding-right: 27px;
        padding-bottom: 27px;
    }

    .finicity-loading {
      position: absolute;
      top: 12px;
      right: 50%;
      left: 50%;
    }

    .finicity-box_close {
        position: absolute;
        right: 18px;
        top: 18px;
    }

    .institution-title {
      margin-top: 54px;
      margin-bottom: 12px;
    }

    .institutions-list-container {
      scrollbar-width: thin;
    }
  }
}

.app-container-dcp_edge .roundedBackground {
    background-color: var(--brand-br-116);
    border-color: var(--brand-br-116);
}

.dashboard-profile-dcp_edge.new-payments-page {
    @apply lg:min-h-screen lg:h-full;
}

.dashboard-profile-dcp_edge .payment-learn-more-container {
    align-items: flex-start;
}

.dashboard-profile-dcp_edge #linkBankAccountBtn {
    margin-top: 0;
}

.dashboard-dcp_edge .partner-logo {
    width: 171.43px;
    height: 48px;
}

.dashboard-dcp_edge .text-t1-headline {
    color: var(--text-t1-color);
}

@media only screen and (min-width: 315px) and (max-width: 640px) {
  .dashboard-dcp_edge .payment-method-make-payment-container {
    width: 100%;
  }
}

