.app-container-deserve_edu .text-1-heading,
.dashboard-deserve_edu .text-1-heading {
    color: var(--theme-text_1_h_color);
    font-size: var(--theme-text_1_h_font_size);
    font-style: var(--theme-text_1_h_font_style);
    line-height: var(--theme-text_1_h_leading);
    font-family: var(--theme-text_1_h_font_family);
    font-weight: var(--theme-text_2_h_font_weight);
    letter-spacing: var(--theme-text_1_h_letter_spacing);
    font-feature-settings: var(--theme-text_1_h_font_feature_settings);
    margin-top: var(--theme-text_1_h_mt);
    padding-top: var(--theme-text_1_h_pt);
}

.app-container-deserve_edu .text-1-heading-2,
.dashboard-deserve_edu .text-1-heading-2 {
    color: var(--theme-text_1_h_2_color);
    font-size: var(--theme-text_1_h_2_font_size);
    font-style: var(--theme-text_1_h_2_font_style);
    line-height: var(--theme-text_1_h_2_leading);
    font-family: var(--theme-text_1_h_2_font_family);
    font-weight: var(--theme-text_2_h_2_font_weight);
    letter-spacing: var(--theme-text_1_h_2_letter_spacing);
    font-feature-settings: var(--theme-text_1_h_2_font_feature_settings);
    margin-top: var(--theme-text_1_h_2_mt);
    padding-top: var(--theme-text_1_h_2_pt);
}

.app-container-deserve_edu .text-1-heading-3,
.dashboard-deserve_edu .text-1-heading-3 {
    color: var(--theme-text_1_h_3_color);
    font-size: var(--theme-text_1_h_3_font_size);
    font-style: var(--theme-text_1_h_3_font_style);
    line-height: var(--theme-text_1_h_3_leading);
    font-family: var(--theme-text_1_h_3_font_family);
    font-weight: var(--theme-text_2_h_3_font_weight);
    letter-spacing: var(--theme-text_1_h_3_letter_spacing);
    font-feature-settings: var(--theme-text_1_h_3_font_feature_settings);
    margin-top: var(--theme-text_1_h_3_mt);
    padding-top: var(--theme-text_1_h_3_pt);
}

.app-container-deserve_edu .text-2-heading,
.dashboard-deserve_edu .text-2-heading {
    color: var(--theme-text_2_h_color);
    font-size: var(--theme-text_2_h_font_size);
    font-style: var(--theme-text_2_h_font_style);
    line-height: var(--theme-text_2_h_leading);
    font-family: var(--theme-text_2_h_font_family);
    font-weight: var(--theme-text_2_h_font_weight);
    letter-spacing: var(--theme-text_2_h_letter_spacing);
    font-feature-settings: var(--theme-text_2_h_font_feature_settings);
    margin-top: var(--theme-text_2_h_mt);
    padding-top: var(--theme-text_2_h_pt);
}

.app-container-deserve_edu .paymentCard .text-1-heading-3,
.dashboard-deserve_edu .paymentCard .text-1-heading-3 {
    margin-bottom: 1rem;
}

.app-container-deserve_edu .paymentCard {
    background-color: var(--theme-card-background);
}

.app-container-deserve_edu .add-pay-method-btn,
.dashboard-deserve_edu .add-pay-method-btn {
    text-align: left;
    margin-left: 2rem
}

.app-container-deserve_edu .brand-btn-void,
.dashboard-deserve_edu .brand-btn-void {
    color: var(--theme-brand_btn_void_color);
    font-size: var(--theme-brand_btn_void_font_size);
    font-style: var(--theme-brand_btn_void_font_style);
    font-weight: var(--theme-brand_btn_void_font_weight);
    line-height: var(--theme-brand_btn_void_leading);
    font-family: var(--theme-brand_btn_void_font_family);
    letter-spacing: var(--theme-brand_btn_void_letter_spacing);
    text-align: var(--theme-brand_btn_void_text_center);
    font-feature-settings: var(--theme-brand_btn_void_font_feature_settings);
    border-color: var(--theme-brand_btn_void_color);
}

.app-container-deserve_edu .brand-btn-solid,
.dashboard-deserve_edu .brand-btn-solid {
    color: var(--theme-brand_btn_solid_color);
    font-size: var(--theme-brand_btn_solid_font_size);
    font-style: var(--theme-brand_btn_solid_font_style);
    font-weight: var(--theme-brand_btn_solid_font_weight);
    line-height: var(--theme-brand_btn_solid_leading);
    font-family: var(--theme-brand_btn_solid_font_family);
    letter-spacing: var(--theme-brand_btn_solid_letter_spacing);
    text-align: var(--theme-brand_btn_solid_text_center);
    font-feature-settings: var(--theme-brand_btn_solid_font_feature_settings);
    border-color: var(--theme-brand_btn_solid_color);
    background-color: var(--theme-brand_btn_solid_bg_color);
}

.app-container-deserve_edu .brand-btn-solid.btn-disabled,
.dashboard-deserve_edu .brand-btn-solid.btn-disabled {
    color: var(--theme-brand_btn_solid_disabled_color);
    background-color: var(--theme-brand_btn_solid_disabled_bg_color);
    border-color: var(--theme-brand_btn_solid_disabled_color);
}

.dashboard-profile-deserve_edu {
    background: #F0F1F4;
}

.dashboard-profile-deserve_edu .page-close {
    background-color: #fff;
}

.dashboard-profile-deserve_edu .page-title {
    margin-top: 2em;
}

.dashboard-profile-deserve_edu .page-sub-title {
    margin-top: 1em;
}

.app-container-deserve_edu .nv-logo {
  width: 130px;
}

/* font fixes */
.app-container-deserve_edu .text-headline-h1 {
    font-family: var(--text-headline-h1-font_family);
    font-weight: var(--text-headline-h1-font_weight);
    font-size: var(--text-headline-h1-font_size);
    font-style: var(--text-headline-h1-font_style);
    line-height: var(--text-headline-h1-line_height);
    letter-spacing: var(--text-b3-letter_spacing);
    color: var(--text-t1-color);
}

.app-container-deserve_edu .text-b3 {
    font-family: var(--text-b3-font_family);
    font-weight: var(--text-b3-font_weight);
    font-size: var(--text-b3-font_size);
    letter-spacing: var(--text-b3-letter_spacing);
}

.app-container-deserve_edu .text-b1 {
    font-family: var(--text-b1-font_family);
    font-weight: var(--text-b1-font_weight);
    font-size: var(--text-b1-font_size);
    letter-spacing: var(--text-b1-letter_spacing);
}

.app-container-deserve_edu .text-b5 {
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    letter-spacing: var(--text-b5-letter_spacing);
    line-height: var(--text-b5-line_height);
}

.app-container-deserve_edu .text-b7 {
    font-family: var(--text-b7-font_family);
    font-weight: var(--text-b7-font_weight);
    font-size: var(--text-b7-font_size);
    letter-spacing: var(--text-b7-letter_spacing);
    line-height: var(--text-b7-line_height);
}

.app-container-deserve_edu .text-c2 {
    font-family: var(--text-t2-font);
    font-weight: var(--text-b1-font_weight);
    font-size: var(--text-c2-std-font_size);
    letter-spacing: var(--text-c2-std-letter_spacing);
}

.app-container-deserve_edu .text-number-1 {
    font-family: var(--text-n1-font_family);
    font-weight: var(--text-n1-font_weight);
    font-size: var(--text-n1-font_size);
    letter-spacing: var(--text-n1-letter_spacing);
}
/* font fixes */

@media only screen and (min-width: 315px) and (max-width: 768px) {
    .app-container-deserve_edu .text-1-heading,
    .dashboard-deserve_edu .text-1-heading {
        font-size: var(--xsm-theme-text_1_h_font_size);
    }

    .app-container-deserve_edu .text-1-heading-2,
    .dashboard-deserve_edu .text-1-heading-2 {
        font-size: var(--xsm-theme-text_1_h_2_font_size);
    }

    .app-container-deserve_edu.signin-flow-page {
        margin-top: 56px;
        padding-top: 4em;
    }
}