/**
 * Source: https://github.com/chinchang/hint.css
 */
[class*="hint--"]:focus,
[class*="hint--"]:hover {
  outline: none;
}

[class*="hint--"] {
  position: relative;
  display: inline-block;
}

[class*="hint--"]:before,
[class*="hint--"]:after {
  position: absolute;
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  transition: 0.1s ease;
  transition-delay: 0ms;
}

[class*="hint--"]:hover:before,
[class*="hint--"]:hover:after,
[class*="hint--"]:focus:before,
[class*="hint--"]:focus:after {
  visibility: visible;
  opacity: 1;
}
[class*="hint--"]:hover:before,
[class*="hint--"]:hover:after,
[class*="hint--"]:focus:before,
[class*="hint--"]:focus:after {
  transition-delay: 50ms;
}

[class*="hint--"]:before {
  content: "";
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  background-color: #fff;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  z-index: 1000001;
}
[class*="hint--"]:after {
  background-color: #fff;
  color: #000;
  padding: 8px 10px;
  font-size: 13px;
  line-height: 1.1;
  white-space: nowrap;
}

[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}
[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}

[aria-label=""]:before,
[aria-label=""]:after,
[data-hint=""]:before,
[data-hint=""]:after {
  display: none !important;
}

.hint--bottom {
  --rotation: -45deg;
}
.hint--bottom:before {
  margin-top: -5.5px;
  transform: rotate(var(--rotation));
}
.hint--bottom:before,
.hint--bottom:after {
  top: 100%;
  left: 50%;
}
.hint--bottom:before {
  left: calc(50% - 6px);
}
.hint--bottom:after {
  transform: translateX(-50%);
}
.hint--bottom:hover:before,
.hint--bottom:focus:before {
  transform: translateY(8px) rotate(var(--rotation));
}
.hint--bottom:hover:after,
.hint--bottom:focus:after {
  transform: translateX(-50%) translateY(8px);
}

.hint--small:after,
.hint--medium:after,
.hint--large:after,
.hint--fit:after {
  box-sizing: border-box;
  white-space: normal;
  line-height: 1.4em;
  word-wrap: break-word;
}

.hint--small:after {
  width: 80px;
}

.hint--medium:after {
  width: 150px;
}

.hint--large:after {
  width: 300px;
}

.hint--fit:after {
  width: 100%;
}

[class*="hint--"]:after {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hint--rounded:before {
  border-radius: 0 1px 0 0;
}
.hint--rounded:after {
  border-radius: 8px;
}
/*  */
/**
 * bottom-right tooltip
 */
.hint--bottom-right {
  --rotation: -45deg;
}
.hint--bottom-right:before {
  margin-top: -5.5px;
  transform: rotate(var(--rotation));
}
.hint--bottom-right:before,
.hint--bottom-right:after {
  top: 100%;
  left: 50%;
}
.hint--bottom-right:before {
  left: calc(50% - 6px);
}
.hint--bottom-right:after {
  transform: translateX(0);
}
.hint--bottom-right:after {
  margin-left: -25px;
}

.hint--bottom-right:hover:before,
.hint--bottom-right:focus:before {
  transform: translateY(8px) rotate(var(--rotation));
}
.hint--bottom-right:hover:after,
.hint--bottom-right:focus:after {
  transform: translateY(8px);
}

/*  */
.hint--medium:after {
  width: 22vw;
}

@media only screen and (max-width: 900px) {
  .hint--medium:after {
    width: 50vw;
  }
}
