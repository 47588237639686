.ignite-index-page .page-main-heading {
    font-family: 'din-pro';
    color: #1F2229;
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 90.909% */
    letter-spacing: -0.3px;
    font-size: 44px;
}

.ignite-index-page .page-sub-heading {
    font-family: 'din-pro';
    color: #221F20;
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 90.909% */
    letter-spacing: -0.3px;
}

.ignite-index-page .page-sub-heading-text {
    font-family: 'avenir-book';
    color: #57595E;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.3px;
}

.ignite-index-page .accordion-title {
    color: #221F20;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'avenir-medium';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    font-size: 16px;
}

.ignite-index-page .accordion-content {
    color: #57595E;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'avenir-book';
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.3px;
    font-size: 16px;
}

.ignite-index-page .footer-text {
    color: #57595E;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.3px;
    @apply lg:text-12px;
}

.ignite-index-page .caption-text {
    color: #1A1717;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "sf-ui-text";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.ignite-index-page .btn-text {
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;
}

.ignite-index-page .qr-code-title {
    color: #221F20;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.3px;
}

.ignite-index-page .qr-code-text {
    color: #221F20;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 13px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: -0.3px;
}

.ignite-index-page .qr-code-container {
    box-shadow: 18px 20px 15px 0px rgba(0, 0, 0, 0.15);
}

.ignite-index-page .lg\:text-44px {
    font-size: 44px;
}

.ignite-index-page .lg\:text-30px {
    font-size: 30px;
}

.ignite-index-page .lg\:text-13px {
    font-size: 13px;
}

.ignite-index-page .lg\:text-12px {
    font-size: 12px;
}

@media only screen and (min-width: 315px) and (max-width: 768px) {
    .ignite-index-page .page-main-heading {
        font-size: 38px;
    }
    .ignite-index-page .page-sub-heading-text {
        font-size: 18px;
    }

    .ignite-index-page .page-sub-heading {
        font-size: 30px;
    }
}

.app-container-slm_ignite .text-1-heading,
.dashboard-slm_ignite .text-1-heading {
    color: var(--theme-text_1_h_color);
    font-size: var(--theme-text_1_h_font_size);
    font-style: var(--theme-text_1_h_font_style);
    line-height: var(--theme-text_1_h_leading);
    font-family: var(--theme-text_1_h_font_family);
    font-weight: var(--theme-text_2_h_font_weight);
    letter-spacing: var(--theme-text_1_h_letter_spacing);
    font-feature-settings: var(--theme-text_1_h_font_feature_settings);
    margin-top: var(--theme-text_1_h_mt);
    padding-top: var(--theme-text_1_h_pt);
}

.app-container-slm_ignite .text-1-heading-2,
.dashboard-slm_ignite .text-1-heading-2 {
    color: var(--theme-text_1_h_2_color);
    font-size: var(--theme-text_1_h_2_font_size);
    font-style: var(--theme-text_1_h_2_font_style);
    line-height: var(--theme-text_1_h_2_leading);
    font-family: var(--theme-text_1_h_2_font_family);
    font-weight: var(--theme-text_2_h_2_font_weight);
    letter-spacing: var(--theme-text_1_h_2_letter_spacing);
    font-feature-settings: var(--theme-text_1_h_2_font_feature_settings);
    margin-top: var(--theme-text_1_h_2_mt);
    padding-top: var(--theme-text_1_h_2_pt);
}

.app-container-slm_ignite .text-1-heading-3,
.dashboard-slm_ignite .text-1-heading-3 {
    color: var(--theme-text_1_h_3_color);
    font-size: var(--theme-text_1_h_3_font_size);
    font-style: var(--theme-text_1_h_3_font_style);
    line-height: var(--theme-text_1_h_3_leading);
    font-family: var(--theme-text_1_h_3_font_family);
    font-weight: var(--theme-text_2_h_3_font_weight);
    letter-spacing: var(--theme-text_1_h_3_letter_spacing);
    font-feature-settings: var(--theme-text_1_h_3_font_feature_settings);
    margin-top: var(--theme-text_1_h_3_mt);
    padding-top: var(--theme-text_1_h_3_pt);
}

.app-container-slm_ignite .text-2-heading,
.dashboard-slm_ignite .text-2-heading {
    color: var(--theme-text_2_h_color);
    font-size: var(--theme-text_2_h_font_size);
    font-style: var(--theme-text_2_h_font_style);
    line-height: var(--theme-text_2_h_leading);
    font-family: var(--theme-text_2_h_font_family);
    font-weight: var(--theme-text_2_h_font_weight);
    letter-spacing: var(--theme-text_2_h_letter_spacing);
    font-feature-settings: var(--theme-text_2_h_font_feature_settings);
    margin-top: var(--theme-text_2_h_mt);
    padding-top: var(--theme-text_2_h_pt);
}

.app-container-slm_ignite .paymentCard .text-1-heading-3,
.dashboard-slm_ignite .paymentCard .text-1-heading-3 {
    margin-bottom: 1rem;
}

.app-container-slm_ignite .add-pay-method-btn,
.dashboard-slm_ignite .add-pay-method-btn {
    text-align: left;
    margin-left: 1.5rem
}

.app-container-deserve_edu .brand-btn-void,
.app-container-slm_ignite .brand-btn-void,
.dashboard-slm_ignite .brand-btn-void {
    color: var(--theme-brand_btn_void_color);
    font-size: var(--theme-brand_btn_void_font_size);
    font-style: var(--theme-brand_btn_void_font_style);
    font-weight: var(--theme-brand_btn_void_font_weight);
    line-height: var(--theme-brand_btn_void_leading);
    font-family: var(--theme-brand_btn_void_font_family);
    letter-spacing: var(--theme-brand_btn_void_letter_spacing);
    text-align: var(--theme-brand_btn_void_text_center);
    font-feature-settings: var(--theme-brand_btn_void_font_feature_settings);
    border-color: var(--theme-brand_btn_void_color);
}

.app-container-deserve_edu .brand-btn-solid,
.dashboard-deserve_edu .brand-btn-solid,
.app-container-slm_ignite .brand-btn-solid,
.dashboard-slm_ignite .brand-btn-solid {
    color: var(--theme-brand_btn_solid_color);
    font-size: var(--theme-brand_btn_solid_font_size);
    font-style: var(--theme-brand_btn_solid_font_style);
    font-weight: var(--theme-brand_btn_solid_font_weight);
    line-height: var(--theme-brand_btn_solid_leading);
    font-family: var(--theme-brand_btn_solid_font_family);
    letter-spacing: var(--theme-brand_btn_solid_letter_spacing);
    text-align: var(--theme-brand_btn_solid_text_center);
    font-feature-settings: var(--theme-brand_btn_solid_font_feature_settings);
    border-color: var(--theme-brand_btn_solid_color);
    background-color: var(--theme-brand_btn_solid_bg_color);
}

.app-container-slm_ignite .brand-btn-solid.btn-disabled,
.dashboard-slm_ignite .brand-btn-solid.btn-disabled {
    color: var(--theme-brand_btn_solid_disabled_color);
    background-color: var(--theme-brand_btn_solid_disabled_bg_color);
    border-color: var(--theme-brand_btn_solid_disabled_color);
}

.dashboard-profile-slm_ignite {
    background: #F7F7F7;
}

.dashboard-profile-slm_ignite .page-title {
    margin-top: 2em;
}

.dashboard-profile-slm_ignite .page-sub-title {
    margin-top: 1em;
}

.dashboard-slm_ignite .text-caption-6,
.dashboard-deserve_edu .text-caption-6,
.dashboard-profile-slm_ignite .text-caption-6 {
    font-family: var(--text-c6-font_family);
    font-weight: var(--text-c6-font_weight);
    font-size: var(--text-c6-font_size);
}

.dashboard-slm_ignite .text-heading-1 {
    font-family: var(--text-h1-font_family);
    font-weight: var(--text-h1-font_weight);
    font-size: var(--text-h1-font_size);
    line-height: var(--text-h1-line_height);
    letter-spacing: var(--text-h1-letter_spacing);
}

.dashboard-slm_ignite .text-heading-2 {
    font-family: var(--text-h2-font_family);
    font-weight: var(--text-h2-font_weight);
    font-size: var(--text-h2-font_size);
    line-height: var(--text-h2-line_height);
}

.dashboard-slm_ignite .text-c1 {
    font-family: var(--text-c1-font_family);
    font-weight: var(--text-c1-font_weight);
    font-size: var(--text-c1-font_size);
    letter-spacing: var(--text-c1-letter_spacing);
}

.dashboard-slm_ignite .text-c2-std {
    font-family: var(--text-c2-std-font_family);
    font-weight: var(--text-c2-std-font_weight);
    font-size: var(--text-c2-std-font_size);
    line-height: var(--text-c2-std-line_height);
    letter-spacing: var(--text-c2-std-letter_spacing);
}

.dashboard-slm_ignite .text-t1-color,
.dashboard-deserve_edu .text-t1-color {
    color: var(--text-t1-color);
}

.dashboard-slm_ignite .text-heading-5, .dashboard-profile-slm_ignite .text-heading-5 {
    font-family: var(--text-h5-font_family);
    font-weight: var(--text-h5-font_weight);
    font-size: var(--text-h5-font_size);
}

.dashboard-slm_ignite .text-number-1 {
    font-family: var(--text-n1-font_family);
    font-weight: var(--text-n1-font_weight);
    font-size: var(--text-n1-font_size);
}

.dashboard-slm_ignite .text-number-3 {
    font-family: var(--text-n3-font_family);
    font-weight: var(--text-n3-font_weight);
    font-size: var(--text-n3-font_size);
    letter-spacing: var(--text-n3-letter_spacing);
}

.dashboard-slm_ignite .text-number-6 {
    font-family: var(--text-n6-font_family);
    font-weight: var(--text-n6-font_weight);
    font-size: var(--text-n6-font_size);
    letter-spacing: var(--text-n6-letter_spacing);
}

.dashboard-deserve_edu .btn-1,
.dashboard-slm_ignite .btn-1 {
    font-family: var(--text-bn1-font_family);
    font-weight: var(--text-bn1-font_weight);
    font-size: var(--text-bn1-font_size);
    letter-spacing: var(--text-bn1-letter_spacing);
    color: var(--text-t4-color);
    background-color: var(--theme-br2);
}

.dashboard-deserve_edu .btn-3,
.dashboard-slm_ignite .btn-3 {
    font-family: var(--text-bn3-font_family);
    font-weight: var(--text-bn3-font_weight);
    font-size: var(--text-bn3-font_size);
}

.dashboard-profile-slm_ignite .text-c1 {
    font-family: var(--text-c1-font_family);
    font-weight: var(--text-c1-font_weight);
    font-size: var(--text-c1-font_size);
    letter-spacing: var(--text-c1-letter_spacing);
}

.dashboard-profile-slm_ignite .text-web {
    font-family: var(--secondary-font);
    font-weight: var(--theme-text_2_h_font_weight);
    font-size: var(--text-h1-sm);
    letter-spacing: var(--letter-spacing);
}

.dashboard-profile-slm_ignite .text-heading-4 {
    font-family: var(--text-h4-font_family);
    font-weight: var(--text-h4-font_weight);
    font-size: var(--text-h4-font_size);
    letter-spacing: var(--text-h4-letter_spacing);
}

.app-container-deserve_edu .text-t2-color,
.dashboard-profile-deserve_edu .text-t2-color,
.dashboard-profile-slm_ignite .text-t2-color
 {
    color: var(--text-t2-color);
}

.dashboard-profile-slm_ignite .text-b3 {
    font-family: var(--text-b3-font_family);
    font-weight: var(--text-b3-font_weight);
    font-size: var(--text-b3-font_size);
    letter-spacing: var(--text-b3-letter_spacing);
}

.dashboard-profile-slm_ignite .text-b4 {
    font-family: var(--text-b4-font_family);
    font-weight: var(--text-b4-font_weight);
    font-size: var(--text-b4-font_size);
    letter-spacing: var(--text-b4-letter_spacing);
}

.dashboard-deserve_edu .text-b5,
.dashboard-slm_ignite .text-b5 {
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    line-height: var(--text-b5-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-slm_ignite .text-b7,
.dashboard-krowdfit .text-b7,
.dashboard-amc .text-b7,
.dashboard-customers_bank .text-b7,
.dashboard-deserve_edu .text-b7 {
    color: var(--text-b7-color);
    font-family: var(--text-b7-font_family);
    font-weight: var(--text-b7-font_weight);
    font-size: var(--text-b7-font_size);
    font-style: var(--text-b7-font_style);
    line-height: var(--text-b7-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-deserve .text-b7 {
    color: var(--theme-card-text);
    font-family: var(--text-b7-font_family);
    font-weight: var(--text-b7-font_weight);
    font-size: var(--text-b7-font_size);
    font-style: var(--text-b7-font_style);
    line-height: var(--text-b7-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-deserve .text-b5 {
    color: var(--theme-card-text);
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    font-style: var(--text-b5-font_style);
    line-height: var(--text-b5-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

.dashboard-slm_ignite .text-b10 {
    font-family: var(--text-b10-font_family);
    font-weight: var(--text-b10-font_weight);
    font-size: var(--text-b10-font_size);
    letter-spacing: var(--text-b10-letter_spacing);
}

.dashboard-deserve_edu .text-b11,
.dashboard-slm_ignite .text-b11 {
    font-family: var(--text-b11-font_family);
    font-weight: var(--text-b11-font_weight);
    font-size: var(--text-b11-font_size);
    letter-spacing: var(--text-b11-letter_spacing);
    line-height: var(--text-b11-line_height);
    color: var(--text-b11-color);
}

.dashboard-slm_ignite .dfc-dashboard-balance {
    line-height: 60px;
    font-weight: 400;
    font-size: 62px;
}

.sidebar-slm_ignite .text-heading-2 {
    font-family: var(--text-h2-font_family);
    font-weight: var(--text-h2-font_weight);
    font-size: var(--text-h2-font_size);
    line-height: var(--text-h2-line_height);
}

.sidebar-slm_ignite .text-b5 {
    font-family: var(--text-b5-font_family);
    font-weight: var(--text-b5-font_weight);
    font-size: var(--text-b5-font_size);
    line-height: var(--text-b5-line_height);
    letter-spacing: var(--text-b5-letter_spacing);
}

@media only screen and (min-width: 315px) and (max-width: 768px) {

    .app-container-slm_ignite .text-1-heading,
    .dashboard-slm_ignite .text-1-heading {
        font-size: var(--xsm-theme-text_1_h_font_size);
    }

    .app-container-slm_ignite .text-1-heading-2,
    .dashboard-slm_ignite .text-1-heading-2 {
        font-size: var(--xsm-theme-text_1_h_2_font_size);
    }

    .app-container-slm_ignite .nv-logo {
        width: 100%;
        height: 100%;
        margin-left: 1em;
    }

    .app-container-slm_ignite.signin-flow-page {
        margin-top: 56px;
        padding-top: 4em;
    }
}